import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  CardTitle,
  Button,
} from "reactstrap"
import Slider from "react-slick"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ArticleCard from "../../additional_components/ArticleCard"
import Line from "../../additional_components/Line"
import articleimage from "../../../src/assets/images/articles/image.png"
import { api, token } from "../../utils/variables"
import axios from "axios"

import FeaturedApps from "../../additional_components/common/dashboardComponent/featuredApps"
import { Link } from "react-router-dom"
// Methods: ['GET'], URL: /details/<slug>
// Methods: ['GET'], URL: /category/<category>
// Methods: ['GET'], URL: /predefined/keywords
// Methods: ['GET'], URL: /predefined/category
// Methods: ['GET'], URL: /stats/trending/meta
// Methods: ['GET'], URL: /stats/trending/sidequest
// Methods: ['GET'], URL: /app/<app_id>

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 5, // Adjust as needed
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Dashboard = () => {
  document.title = "Trivium Home"

  const [dataAction, setDataAction] = useState("loading")
  const [trendingApps, setTrendingApps] = useState([])
  const [newReleases, setNewReleases] = useState([])
  const [trendingAppsMeta, setTrendingAppsMeta] = useState([])
  const [trendingAppsSidequest, setTrendingAppsSidequest] = useState([])

  useEffect(() => {
    fetchNewReleases()
    fetchTrendingApps()
  }, [])

  const fetchTrendingApps = async () => {
    //https://api.triviumpro.in/predefined/keywords

    const storedData = sessionStorage.getItem("trendingApps")
    if (storedData) {
      setTrendingApps(JSON.parse(storedData))
    } else {
      try {
        // Fetch first list of trending apps
        const resMeta = await axios.get(
          `${api}/tracker/stats/meta/trending`,
          {}
        )
        const trendingAppsMeta = resMeta.data.games

        // Fetch second list of trending apps
        const resSidequest = await axios.get(
          `${api}/tracker/stats/sidequest/trending`,
          {}
        )
        const trendingAppsSidequest = resSidequest.data.games

        // Combine the two lists
        const combinedTrendingApps = [
          ...trendingAppsMeta,
          ...trendingAppsSidequest,
        ]

        //adding data in session item
        sessionStorage.setItem(
          "trendingApps",
          JSON.stringify(combinedTrendingApps)
        )

        // Update state with the combined list
        setTrendingApps(combinedTrendingApps)
        // console.log(combinedTrendingApps)
        setDataAction(resMeta.data.action || resSidequest.data.action)
      } catch (error) {
        console.error("Error fetching trending apps:", error)
      }
    }
  }
  const fetchNewReleases = async () => {
    const storedNRData = sessionStorage.getItem("newReleases")
    if (storedNRData) {
      setNewReleases(JSON.parse(storedNRData))
    } else {
      try {
        // Initiate both API requests simultaneously
        const [metaResult, sidequestResult] = await Promise.allSettled([
          axios.get(`${api}/tracker/stats/meta/releases`),
          axios.get(`${api}/tracker/stats/sidequest/releases`),
        ])

        // Parse results
        console.log(metaResult)
        const metaData =
          metaResult.status === "fulfilled" ? metaResult.value.data.games : []
        const sidequestData =
          sidequestResult.status === "fulfilled"
            ? sidequestResult.value.data.games
            : []

        // Combine the data
        const combinedNewReleases = [...metaData, ...sidequestData]

        // Store data in session storage
        sessionStorage.setItem(
          "newReleases",
          JSON.stringify(combinedNewReleases)
        )

        // Set initial data to state for immediate display, even if one API took longer
        setNewReleases(combinedNewReleases)

        // Optionally, set an action or other status if needed from the responses
        const action =
          metaResult.status === "fulfilled"
            ? metaResult.data.action
            : sidequestResult.status === "fulfilled"
            ? sidequestResult.data.action
            : null

        setDataAction(action)
      } catch (error) {
        console.error("Error fetching new releases:", error)
      }
    }
  }

  // const fetchNewReleases = async () => {
  //   const storedNRData = sessionStorage.getItem("newReleases")
  //   if (storedNRData) {
  //     setNewReleases(JSON.parse(storedNRData))
  //   } else {
  //     try {
  //       // Fetch first list of trending apps
  //       const metaResult = await axios.get(
  //         `${api}/tracker/stats/meta/releases`,
  //         {}
  //       )

  //       const newMeta = metaResult.data.games
  //       console.log(newMeta)
  //       // Fetch second list of trending apps
  //       const sidequestResult = await axios.get(
  //         `${api}/tracker/stats/sidequest/releases`,
  //         {}
  //       )
  //       const newSidequest = sidequestResult.data.games

  //       // console.log("in try block")
  //       // // Initiate both API requests simultaneously
  //       // const [metaResult, sidequestResult] = await Promise.allSettled([
  //       //   axios.get(`${api}/tracker/stats/meta/releases`, {}),
  //       //   axios.get(`${api}/tracker/stats/sidequest/releases`, {}),
  //       // ])

  //       // Parse results

  //       // const metaData =
  //       //   metaResult.data.message == "Successfully fetched data"
  //       //     ? metaResult.data.games
  //       //     : []
  //       // const sidequestData =
  //       //   sidequestResult.data.message == "Successfully fetched data"
  //       //     ? sidequestResult.data.games
  //       //     : []

  //       // Combine the data
  //       const combinedNewReleases = [...newMeta, ...newSidequest]
  //       console.log(combinedNewReleases)

  //       // Store data in session storage
  //       sessionStorage.setItem(
  //         "newReleases",
  //         JSON.stringify(combinedNewReleases)
  //       )
  //       setNewReleases(combinedNewReleases)
  //       // Set initial data to state for immediate display, even if one API took longer
  //       //setNewReleases(combinedNewReleases)
  //     } catch (error) {
  //       console.error("Error fetching new releases:", error)
  //     }
  //   }
  // }

  // const games = [
  //   {
  //     id: 1,
  //     title: "MARVEL Fight",
  //     type: "Role Playing",
  //     description:
  //       "An epic blockbuster action-RPG featuring Super Heroes and Villains from the Marvel Universe!",
  //     imageUrl:
  //       "https://www.google.com/url?sa=i&url=https%3A%2F%2Fencrypted-tbn3.gstatic.com%2Fimages%3Fq%3Dtbn%3AANd9GcRxPVdeK0NW7RbJJhOHsUt0HneXsQm3qwlYbtYpNxP2NcTtnG0Q&psig=AOvVaw1YVvelbAtmazINsjPJ97UH&ust=1725704742971000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCLC_xqqNrogDFQAAAAAdAAAAABAE",
  //   },
  //   {
  //     id: 2,
  //     title: "Clash Of Clans",
  //     type: "Strategy",
  //     description:
  //       "Enter the Arena! Build your Battle Deck and outsmart the enemy in fast real-time battles. From the creators of CLASH OF CLANS.",
  //     imageUrl:
  //       "https://www.google.com/url?sa=i&url=https%3A%2F%2Fencrypted-tbn0.gstatic.com%2Fimages%3Fq%3Dtbn%3AANd9GcRAZLLZw4gcNJq2eDdalgOwlVglyv_rrGD-WG4C4T9UaTFTLXRs&psig=AOvVaw1PV3K2P7W6PwmTBloLETS9&ust=1725704641896000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCNCOpoaNrogDFQAAAAAdAAAAABAE",
  //   },
  //   {
  //     id: 3,
  //     title: "Drift Max Pro",
  //     type: "Racing",
  //     description:
  //       "From the creators of the legendary drift racing game Drift Max comes an epic new drifting game.",
  //     imageUrl:
  //       "https://downloadr2.apkmirror.com/wp-content/uploads/2023/02/21/63ebca979caa6.png",
  //   },
  //   {
  //     id: 4,
  //     title: "BGMI",
  //     type: "Shooting",
  //     description:
  //       "This is not a mission for the weak hearted. You will be put in the line of fire against a tough and determined enemy.",
  //     imageUrl:
  //       "https://upload.wikimedia.org/wikipedia/en/thumb/6/63/Battleground_Mobile_India.webp/240px-Battleground_Mobile_India.webp.png",
  //   },
  //   {
  //     id: 5,
  //     title: "Game 5",
  //     type: "Shooting",
  //     description:
  //       "This is not a mission for the weak hearted. You will be put in the line of fire against a tough and determined enemy.",
  //     imageUrl:
  //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxjv-zxssoyrGBYomWZmqnpghauUWdW7T76wm7DuJXYkj_uhmTjkzFXa2EAfWVMm6kNvsFBg",
  //   },
  //   {
  //     id: 6,
  //     title: "Game 6",
  //     type: "Shooting",
  //     description:
  //       "This is not a mission for the weak hearted. You will be put in the line of fire against a tough and determined enemy.",
  //     imageUrl: "https://via.placeholder.com/150",
  //   },
  //   {
  //     id: 7,
  //     title: "Game 7",
  //     type: "Racing",
  //     description:
  //       "This is not a mission for the weak hearted. You will be put in the line of fire against a tough and determined enemy.",
  //     imageUrl:
  //       "https://images.crazygames.com/xtreme-rivals-car-racing_16x9/20240509070904/xtreme-rivals-car-racing_16x9-cover?auto=format,compress&q=75&cs=strip",
  //   },
  // ]
  const articles = [
    {
      id: 1,
      title: "Trends in Meta and SideQuest VR Markets",
      content: [
        "The VR market is experiencing rapid growth, driven by advancements in hardware, software, and user adoption. Major trends include the increasing popularity of standalone VR headsets, like the Meta Quest series, which offer powerful yet affordable experiences without the need for external hardware. Another significant trend is the rise of social VR spaces and multiplayer experiences, where users can interact in virtual environments, fueling the demand for social connectivity in immersive worlds.",
        "Enterprise adoption of VR is also growing, with sectors like healthcare, education, and remote training using VR for simulations and learning. Additionally, the integration of haptic feedback and hand-tracking technologies is enhancing the realism of interactions, while the development of the metaverse is pushing more investment into VR content and platforms. Overall, as VR hardware becomes more accessible and content evolves, the market is set to expand, with gaming, enterprise, and social applications at the forefront of this growth.",
      ],
      imageUrl: articleimage,
      publishDate: "July 5, 2024",
    },
  ]

  const CarouselComponent = ({ game, onGameClick }) => {
    return (
      <Slider {...carouselSettings}>
        {game.map((app, index) => (
          <Card
            key={index}
            style={{
              margin: "20px",
              maxWidth: "400px",
              minHeight: "150px",
              maxHeight: "250px",
            }}
            onClick={() => console.log(app.name)}
            //onClick={() => onGameClick(app.name)}
          >
            <Link
              to={`/gamedashboard?id=${app.item_id}`}
              className=""
              state={{ app_id: app.item_id, data: app }}
            >
              <CardImg
                top
                width="100%"
                src={app.image_url}
                alt="Game Image"
                style={{ minHeight: 100, maxHeight: 100, objectfit: "cover" }}
              />
              <CardBody
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 100,
                  maxHeight: 100,
                }}
              >
                <CardTitle tag="h5">{app.name}</CardTitle>
                {/* <p>{game.type}</p>
  <CardText className="truncate-text">{game.description}</CardText> */}
              </CardBody>
            </Link>
          </Card>
        ))}
      </Slider>
    )
  }

  const [selectedGame, setSelectedGame] = useState(null)
  const handleGameClick = gameTitle => {
    setSelectedGame(gameTitle)
    console.log("Selected Game:", gameTitle) // Debug: Check if title is logged
  }

  return (
    <React.Fragment>
      <Row>
        {/* Main Column */}
        <Col md={10}>
          <div className="page-content">
            <Breadcrumbs
              title="Trivium"
              breadcrumbItem="Trends in Meta and other platforms"
            />
            <Container fluid>
              <Container>
                {articles.map(article => (
                  <ArticleCard article={article} />
                ))}

                {/* <ArticleCard article={article}/> */}
              </Container>
              <div style={{ marginTop: 50 }}>
                <Breadcrumbs
                  title="Trivium"
                  breadcrumbItem="Top Trending Games"
                />
                <Container>
                  <Row className="p-2">
                    <CarouselComponent
                      key="trendingCarousel"
                      game={trendingApps}
                      //onGameClick={handleGameClick}
                    />
                  </Row>
                  <Row>{selectedGame && <Line game={selectedGame} />}</Row>
                </Container>
              </div>

              <div style={{ marginTop: 50 }}>
                <Breadcrumbs title="New Release" breadcrumbItem="New Release" />
                <Container>
                  <Row className="p-2">
                    <CarouselComponent
                      key="newReleaseCarousel"
                      game={newReleases}
                      //onGameClick={handleGameClick}
                    />
                  </Row>
                  {/* <Row>{selectedGame && <Line game={selectedGame} />}</Row> */}
                </Container>
              </div>
            </Container>
          </div>
        </Col>

        {/* Sidebar Column */}
        <Col md={2} style={{ borderLeft: "1px solid black" }}>
          <div className="page-content">
            <Breadcrumbs title="Trivium" breadcrumbItem="Featured App" />
            <div style={{ marginTop: 20 }}>
              {trendingApps.slice(0, 5).map((app, index) => (
                <FeaturedApps app={app} key={app.item_id}></FeaturedApps>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard
