import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { FaCirclePlus } from "react-icons/fa6"
import AppListModal from "../../additional_components/common/Modals/AppListModal"
import { Link } from "react-router-dom"
import classnames from "classnames"
import ComparisonTabs from "../AppTracking/comparisonTabs"
import { api } from "../../utils/variables"
import axios from "axios"
import FeatureTabs from "./featureTabs"
import AppSearchModal from "../../additional_components/common/Modals/AppSearchModal"
import metalogo from "../../../src/assets/images/meta-logo.png"
import sidelogo from "../../../src/assets/images/SideQuest-Mark-White.png"
import KeywordDataTable from "../../additional_components/marketResearch/keywordTracking/KeywordDataTable"

export default function KeywordTracking2() {
  // const [activeTab1, setactiveTab1] = useState("5")

  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)

  const [trackedKeyword, setTrackedKeyword] = useState([])

  const [trackedApps, setTrackedApps] = useState([])

  const [selectedApp, setSelectedApp] = useState(null)
  const [selectedApp1, setSelectedApp1] = useState(null)
  const [selectedApp2, setSelectedApp2] = useState(null)
  const [selectedApp3, setSelectedApp3] = useState(null)

  const [loading, setLoading] = useState(false)
  const [sectionLoading, setSectionLoading] = useState(false)
  const [predefKeyword, setpredef] = useState([])

  const [trackedKeywordData, setTrackedKeywordData] = useState([])

  const [storedgameAppData, setStoredgameAppData] = useState(
    sessionStorage.getItem("firstAppKeyword")
  )

  const [activeTab, setactiveTab] = useState("1")

  const [isAddDisabled, setIsAddDisabled] = useState(false)

  function toggletab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const fetchpredefinedwords = () => {
    axios
      .get(`${api}/tracker/predefined/keyword`, {})
      .then(res => {
        //console.log(res.data.keywords)
        setpredef(res.data.keywords)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  useEffect(() => {
    getTrackedApps()
    //console.log(sessionStorage.getItem("firstAppKeyword"))
    setSelectedApp1(JSON.parse(sessionStorage.getItem("firstAppKeyword")))
    setSelectedApp2(JSON.parse(sessionStorage.getItem("secondAppKeyword")))
    setSelectedApp3(JSON.parse(sessionStorage.getItem("thirdAppKeyword")))
    trackKeyword(JSON.parse(sessionStorage.getItem("firstAppKeyword"))?.item_id)

    fetchpredefinedwords()
    //getAppDetails(JSON.parse(sessionStorage.getItem("firstAppKeyword")), "1")
    // console.log(`List on load: ${comparisonBetween}`)
  }, [])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  const handleselectedapp = async app => {
    // setLoading(true)

    axios.get(`${api}/tracker/app/${app?.item_id}`, {}).then(res => {
      switch (activeTab) {
        case "1":
          setSelectedApp1(res.data.details)
          break
        case "2":
          setSelectedApp2(res.data.details)
          break
        case "3":
          setSelectedApp3(res.data.details)
          break
      }

      //   setSelectedApp(res.data.details)

      //adding session element
      sessionStorage.setItem(
        "selectedAppKeyword",
        JSON.stringify(res.data.details)
      )
      console.log(res.data.details)
    })
    trackKeyword(app.item_id)
    toggle_modal()
  }

  const getAppDetails = (app, tabnum) => {
    axios.get(`${api}/tracker/app/${app?.item_id}`, {}).then(res => {
      switch (tabnum) {
        case "1":
          if (selectedApp1) setSelectedApp1(res.data.details)
          break
        case "2":
          if (selectedApp2) setSelectedApp2(res.data.details)
          break
        case "3":
          if (selectedApp3) setSelectedApp3(res.data.details)
          break
      }
    })
  }
  // add keyword b y pressing enter
  const handleKeyDown1 = event => {
    if (event.key === "Enter") {
      setIsAddDisabled(true)
      //handleSearch() // Call the function when Enter is pressed
      if (trackedKeyword.length < 5) {
        if (!trackedKeyword.includes(inputValue.trim())) {
          //   setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
          addtoTrackKeywords(selectedApp1.item_id, inputValue)
        }
        setInputValue("") // Clear the input field
      } else {
        alert("Only 5 keywords can be tracked")
      }
    }
  }
  const handleKeyDown2 = event => {
    if (event.key === "Enter") {
      setIsAddDisabled(true)
      //handleSearch() // Call the function when Enter is pressed
      if (trackedKeyword.length < 5) {
        if (!trackedKeyword.includes(inputValue.trim())) {
          //   setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
          addtoTrackKeywords(selectedApp2.item_id, inputValue)
        }
        setInputValue("") // Clear the input field
      } else {
        alert("Only 5 keywords can be tracked")
      }
    }
  }
  const handleKeyDown3 = event => {
    if (event.key === "Enter") {
      setIsAddDisabled(true)
      //handleSearch() // Call the function when Enter is pressed
      if (trackedKeyword.length < 5) {
        if (!trackedKeyword.includes(inputValue.trim())) {
          //   setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
          addtoTrackKeywords(selectedApp3.item_id, inputValue)
        }
        setInputValue("") // Clear the input field
      } else {
        alert("Only 5 keywords can be tracked")
      }
    }
  }

  async function addtoTrackKeywords(game_id, selectedWord) {
    const gamedata = { item_id: game_id, keyword: selectedWord }
    //const cat = {  }
    console.log(gamedata)
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/add_keyword_tracking`,
        gamedata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { item_id: gameid, category: selectedCategory }, // Adjust this key if the payload requires a different name
        }
      )
      //   console.log(res)
      await trackKeyword(game_id)
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setIsAddDisabled(false)
    }
  }

  async function trackKeyword(game_id) {
    // console.log(game_id, category)
    try {
      const res = await axios.get(
        `${api}/tracker/tracking/get_keyword_tracking?item_id=${game_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      const trackedword = res.data?.data || []
      console.log(trackedword)
      setTrackedKeywordData(trackedword)

      //extracting keywords for individual app
      const extractedKeywords = Object.values(res.data?.data)
        .filter(item => typeof item === "object" && item.keyword) // Filter objects with the keyword property
        .map(item => item.keyword) // Map to keyword values
      //console.log(extractedKeywords)

      setTrackedKeyword(extractedKeywords)
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      console.log(activeTab)
      setLoading(false)
      setSectionLoading(false)
    }
  }
  //add keyword by clicking add button
  const handleAddButton = async () => {
    if (trackedKeyword.length < 5) {
      if (!trackedKeyword.includes(inputValue.trim())) {
        //setTrackedKeyword(prevItems => [...prevItems, inputValue.trim()]) // Add inputValue to items
      }
      setInputValue("")
    } else {
      alert("Only 5 keywords can be tracked")
    }
  }

  //removing word from keyword array
  const removeItem = async (gameid, word) => {
    setLoading(true)
    const data = { game_id: gameid, entity_type: "keyword", entity_id: word }
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/remove_entity`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { game_id: gameid, entity_type: "keyword", entity_id: word },
        }
      )
      await trackKeyword(gameid)
      //console.log(res)
    } catch (error) {
      console.error("Error getting data:", error)
    }
  }
  const removeApp = async (gameid, word) => {
    console.log(gameid)
    setLoading(true)
    const data = { game_id: gameid, entity_type: "keyword", entity_id: null }
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/remove_entity`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { game_id: gameid, entity_type: "keyword", entity_id: word },
        }
      )
      console.log(res)
      await getTrackedApps()
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setLoading(false)
    }
  }
  const getTrackedApps = async () => {
    if (JSON.parse(sessionStorage.getItem("firstAppKeyword")) == null) {
      setLoading(true)
    }

    try {
      const res = await axios.get(
        `${api}/tracker/tracking/get_user_tracked_keywords`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      const apps = res.data?.data?.game_data || []
      setSelectedApp1(apps?.[0] || null)
      //adding session element
      sessionStorage.setItem(
        "firstAppKeyword",
        JSON.stringify(apps?.[0] || null)
      )
      setSelectedApp2(apps?.[1] || null)
      sessionStorage.setItem(
        "secondAppKeyword",
        JSON.stringify(apps?.[1] || null)
      )

      setSelectedApp3(apps?.[2] || null)
      sessionStorage.setItem(
        "thirdAppKeyword",
        JSON.stringify(apps?.[2] || null)
      )
      console.log(apps)
      setTrackedApps(apps)
      //   console.log(res)
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb
          title="New Keyword Tracking "
          breadcrumbItem="New Keyword Tracking"
        />
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("1")

                trackKeyword(selectedApp1?.item_id)
                //getAppDetails(selectedApp1, "1")
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Game 1</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("2")

                trackKeyword(selectedApp2?.item_id)
                //getAppDetails(selectedApp2, "2")
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Game 2</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("3")

                trackKeyword(selectedApp3?.item_id)
                //getAppDetails(selectedApp3, "3")
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">Game 3</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp1 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp1?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp1?.name || "Demo1"}
                                </b>

                                <CardText>
                                  {selectedApp1?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp1?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp1?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp1?.item_id}`}
                                    >
                                      {" "}
                                      <p>
                                        All games from:{" "}
                                        {selectedApp1?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp1?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp1?.supported_platforms !=
                                    null ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp1 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp1?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {selectedApp1 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        trackKeyword(selectedApp1.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Keyword Section</CardTitle>

                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword.map((value, index) => {
                              return (
                                <Button
                                  key={index}
                                  color="dark"
                                  className="btnparent btn-rounded"
                                  onClick={() => {
                                    setIsAddDisabled(true)
                                    //console.log(`selected word:${value}`)
                                    if (trackedKeyword.length < 5) {
                                      if (
                                        !trackedKeyword.includes(value.trim())
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackKeywords(
                                        selectedApp1.item_id,
                                        value
                                      )
                                    } else {
                                      alert("only 5 keywords can be added")
                                    }
                                  }}
                                >
                                  <div className="keywordbtn">{value}</div>
                                </Button>
                              )
                            })}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Keyword
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="text"
                                disabled={isAddDisabled}
                                className="form-control"
                                id="horizontal-firstname-input"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown1}
                              />
                            </Col>
                            <Col sm={2}>
                              <Button
                                color="primary"
                                disabled={isAddDisabled}
                                onClick={() => {
                                  setIsAddDisabled(true)
                                  handleAddButton()
                                  addtoTrackKeywords(
                                    selectedApp1.item_id,
                                    inputValue
                                  )
                                }}
                              >
                                ADD
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
                              <b className="col-sm-2">Tracked Words:</b>
                              {trackedKeyword.map((value, index) => {
                                return (
                                  <div id="liveAlertPlaceholder">
                                    <div>
                                      <Alert
                                        color="primary"
                                        isOpen={true}
                                        toggle={() =>
                                          removeItem(
                                            selectedApp1.item_id,
                                            value
                                          )
                                        }
                                      >
                                        {value}
                                      </Alert>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {/* <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs> */}
                      {/* <FeatureTabs appListData={comparisonBetween}></FeatureTabs> */}
                      <KeywordDataTable
                        keywordData={trackedKeywordData}
                      ></KeywordDataTable>
                    </Row>
                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>

          <TabPane tabId="2">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp2 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp2?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp2?.name || "Demo1"}
                                </b>

                                <CardText>
                                  {selectedApp2?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp2?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp2?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp2?.item_id}`}
                                    >
                                      {" "}
                                      <p>
                                        All games from:{" "}
                                        {selectedApp2?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp2?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp2?.supported_platforms !=
                                    null ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp2 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp2?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {selectedApp2 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        trackKeyword(selectedApp2.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Keyword Section</CardTitle>

                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword.map((value, index) => {
                              return (
                                <Button
                                  key={index}
                                  color="dark"
                                  className="btnparent btn-rounded"
                                  onClick={() => {
                                    setIsAddDisabled(true)
                                    //console.log(`selected word:${value}`)
                                    if (trackedKeyword.length < 5) {
                                      if (
                                        !trackedKeyword.includes(value.trim())
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackKeywords(
                                        selectedApp2.item_id,
                                        value
                                      )
                                    } else {
                                      alert("only 5 keywords can be added")
                                    }
                                  }}
                                >
                                  <div className="keywordbtn">{value}</div>
                                </Button>
                              )
                            })}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Keyword
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="text"
                                disabled={isAddDisabled}
                                className="form-control"
                                id="horizontal-firstname-input"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown2}
                              />
                            </Col>
                            <Col sm={2}>
                              <Button
                                color="primary"
                                disabled={isAddDisabled}
                                onClick={() => {
                                  setIsAddDisabled(true)
                                  handleAddButton()
                                  addtoTrackKeywords(
                                    selectedApp2.item_id,
                                    inputValue
                                  )
                                }}
                              >
                                ADD
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
                              <b className="col-sm-2">Tracked Words:</b>
                              {trackedKeyword.map((value, index) => {
                                return (
                                  <div id="liveAlertPlaceholder">
                                    <div>
                                      <Alert
                                        color="primary"
                                        isOpen={true}
                                        toggle={() =>
                                          removeItem(
                                            selectedApp2.item_id,
                                            value
                                          )
                                        }
                                      >
                                        {value}
                                      </Alert>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {/* <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs> */}
                      {/* <FeatureTabs appListData={comparisonBetween}></FeatureTabs> */}
                      <KeywordDataTable
                        keywordData={trackedKeywordData}
                      ></KeywordDataTable>
                    </Row>
                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>

          <TabPane tabId="3">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp3 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp3?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp3?.name || "Demo1"}
                                </b>

                                <CardText>
                                  {selectedApp3?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp3?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp3?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp3?.item_id}`}
                                    >
                                      {" "}
                                      <p>
                                        All games from:{" "}
                                        {selectedApp3?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp3?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp3?.supported_platforms !=
                                    null ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp3 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp3?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {selectedApp3 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        trackKeyword(selectedApp3.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Keyword Section</CardTitle>

                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword.map((value, index) => {
                              return (
                                <Button
                                  key={index}
                                  color="dark"
                                  className="btnparent btn-rounded"
                                  onClick={() => {
                                    setIsAddDisabled(true)
                                    //console.log(`selected word:${value}`)
                                    if (trackedKeyword.length < 5) {
                                      if (
                                        !trackedKeyword.includes(value.trim())
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackKeywords(
                                        selectedApp3.item_id,
                                        value
                                      )
                                    } else {
                                      alert("only 5 keywords can be added")
                                    }
                                  }}
                                >
                                  <div className="keywordbtn">{value}</div>
                                </Button>
                              )
                            })}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Keyword
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="text"
                                disabled={isAddDisabled}
                                className="form-control"
                                id="horizontal-firstname-input"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown3}
                              />
                            </Col>
                            <Col sm={2}>
                              <Button
                                color="primary"
                                disabled={isAddDisabled}
                                onClick={() => {
                                  setIsAddDisabled(true)
                                  handleAddButton()
                                  addtoTrackKeywords(
                                    selectedApp3.item_id,
                                    inputValue
                                  )
                                }}
                              >
                                ADD
                              </Button>
                            </Col>
                          </Row>

                          <Row>
                            <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
                              <b className="col-sm-2">Tracked Words:</b>
                              {trackedKeyword.map((value, index) => {
                                return (
                                  <div id="liveAlertPlaceholder">
                                    <div>
                                      <Alert
                                        color="primary"
                                        isOpen={true}
                                        toggle={() =>
                                          removeItem(
                                            selectedApp3.item_id,
                                            value
                                          )
                                        }
                                      >
                                        {value}
                                      </Alert>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {/* <ComparisonTabs appListData={comparisonBetween}></ComparisonTabs> */}
                      {/* <FeatureTabs appListData={comparisonBetween}></FeatureTabs> */}
                      <KeywordDataTable
                        keywordData={trackedKeywordData}
                      ></KeywordDataTable>
                    </Row>
                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>
        </TabContent>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Spinner size="lg" color="primary" />
        </div>
      )}
      <AppSearchModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        onAppSelect={handleselectedapp}
      ></AppSearchModal>
    </React.Fragment>
  )
}
