import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardFooter,
  Collapse,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import withRouter from "../../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-4.jpg"
import { Link } from "react-router-dom"
import PlanCards from "./PlansComponent"
// actions

const PaymentMethod = props => {
  document.title = " Profile | Payment Method"

  const dispatch = useDispatch()

  // const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState("")
  const [visible, setVisible] = useState(false)
  const storedUsername = localStorage.getItem("userName")
  const email = localStorage.getItem("userEmail")
  const [isOpenPaymentinfo, setIsOpenPaymentinfo] = useState(true)
  const togglePaymentinfo = () => setIsOpenPaymentinfo(!isOpenPaymentinfo)

  useEffect(() => {
    console.log("user profile page")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Payment Method" breadcrumbItem="Payment Method" />

          <Card>
            <Link
              onClick={togglePaymentinfo}
              className="collapsed text-reset"
              to="#"
            >
              <div className="p-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-bill text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    {/* <h5 className="font-size-16 mb-1">Payment Method</h5> */}
                    <p className="text-muted text-truncate mb-0">
                      Change your payment method
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-chevron-down accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </div>
            </Link>
            <Collapse isOpen={isOpenPaymentinfo}>
              <div className="p-4 border-top">
                <div>
                  {/* <h5 className="font-size-14 mb-3">Payment method :</h5> */}
                  <Row>
                    <Col lg={3} col={6}>
                      <div>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            name="pay-method"
                            id="pay-methodoption1"
                            className="card-radio-input"
                          />

                          <span className="card-radio text-center text-truncate">
                            <i className="uil uil-postcard d-block h2 mb-3"></i>
                            Credit / Debit Card
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col lg={3} col={6}>
                      <div>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            name="pay-method"
                            id="pay-methodoption2"
                            className="card-radio-input"
                          />

                          <span className="card-radio text-center text-truncate">
                            <i className="uil uil-paypal d-block h2 mb-3"></i>
                            Paypal
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-sm-end mt-2 mt-sm-0">
                        <Link to="#" className="btn btn-success">
                          <i className="uil uil-shopping-cart-alt me-1"></i>{" "}
                          Procced{" "}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentMethod
