import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { api } from "../../utils/variables"
import { Row, Spinner, Toast, ToastBody } from "reactstrap"

export default function VerifyUser() {
  const loc = useLocation()
  const { token } = useParams()
  console.log(token)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (token) verify()
  }, [token])

  const verify = async () => {
    const res = await axios.get(`${api}/user/verify/${token}`)
    console.log(res)
    if (res.data?.message === "Account verified. Log in to continue") {
      alert(res.data?.message)
    } else {
      alert(res.data?.error)
    }
    navigate("/login")
    setLoading(false)
  }

  return (
    <div>
      {loading ? (
        <Row className="mt-4">
          <div className="d-flex justify-content-center">
            <Spinner size="lg" color="primary" />
          </div>
        </Row>
      ) : (
        <p></p>
      )}
    </div>
  )
}
