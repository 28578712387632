import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useLocation } from "react-router-dom"

// Function to convert "dd-mm-yy" to a standard Date object
const convertToDate = dateStr => {
  const [year, month, day] = dateStr.split("-")
  return new Date(`${year}`, month - 1, day) // Adjusting for year and month being 0-indexed
}

// Function to generate dates between start and end date
const generateDateRange = (start, end) => {
  const startDate = convertToDate(start)
  const endDate = convertToDate(end)
  const dates = []

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const formattedDate = `${String(date.getDate()).padStart(
      2,
      "0"
    )}-${date.toLocaleString("default", {
      month: "short",
    })}-${date.getFullYear()}`
    dates.push(formattedDate)
  }

  return dates
}
const adjustDataToDateRange = (data, dateRangeLength) => {
  if (data?.length > dateRangeLength) {
    // Truncate data to match the length of the date range
    return data?.slice(0, dateRangeLength)
  } else {
    // Extend data with null or placeholders to match the length of the date range
    return [...data, ...Array(dateRangeLength - data?.length).fill(null)]
  }
}

// Map data according to the date range
const mapDataToDates = (jsonData, dateRange) => {
  const dateToValueMap = new Map(
    jsonData.map(entry => [entry.created_at_date, entry.ktr])
  )

  return dateRange.map(date => dateToValueMap.get(date) || null)
}

const RankedDashedLine = ({ jsonData, startDate, endDate }) => {
  const location = useLocation()
  // const { params } = useParams()
  const { app_id, data, selected_tab } = location.state || {}
  const [xAxisCategories, setXAxisCategories] = useState([])
  const [topGrossingData, setTopGrossingData] = useState([])
  const [freeRankData, setFreeRankData] = useState([])
  //const baseTopGross = jsonData?.map(entry => entry.ktr)
  const baseTopGross = [
    36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9, 36, 42,
    60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9,
  ]

  const series = [
    {
      name: "Top Grossing",

      data: topGrossingData,
    },
    {
      name: "Free Rank",
      data: freeRankData,
    },
    // {
    //   name: "Total Visits",
    //   data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49, 26, 21, 20, 6, 8],
    // },
  ]
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#f1b44c", "#34c38f"],
    //"#f1b44c"
    //"#5b73e8"
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {},
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: xAxisCategories,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)"
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }
  // Update x-axis whenever startDate or endDate changes
  useEffect(() => {
    console.log(baseTopGross)
    const newCategories = generateDateRange(startDate, endDate)
    setXAxisCategories(newCategories)

    // Map jsonData to the date range
    const alignedTopGrossingData = mapDataToDates(jsonData, newCategories)

    setTopGrossingData(
      adjustDataToDateRange(baseTopGross, newCategories.length)
    )
    //setFreeRankData(adjustDataToDateRange(baseFreeRank, newCategories.length))
  }, [startDate, endDate])

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default RankedDashedLine
