import axios from "axios"
import { useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { api, token } from "../../../utils/variables"
import { FaLock } from "react-icons/fa"

export default function SubscriptionPopUpModal({ modaltoggle, togglemodal }) {
  const [inputValue, setInputValue] = useState("")
  const [visible, setVisible] = useState(false)
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="lg"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header">
        <div className="d-flex justify-content-start gap-2 align-items-center">
          <FaLock></FaLock>
          <h5 className="modal-title" id="staticBackdropLabel">
            Upgrade your plan to unlock this feature
          </h5>
        </div>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        style={{
          maxHeight: "80vh",
          // minHeight: "40vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div>
          {/* return search list */}
          <Row classname="d-flex align-items-stretch">
            <div id="liveAlertPlaceholder">
              <div>
                <Alert
                  color="info"
                  isOpen={visible}
                  toggle={() => setVisible(false)}
                >
                  Buying option is not available for now!
                </Alert>
              </div>
            </div>
            <Col className="mt-4">
              <Card className="d-flex justify-content-center align-items-center text-center h-100">
                <CardBody>
                  <div className="">
                    <p className="text-white">Standard</p>
                    <ul>
                      <li>
                        Advanced tracking with access to 100 keywords and
                        categories apps.
                      </li>
                      <li>
                        Monitor up to 10 apps and track up to 15
                        keywords/categories.
                      </li>
                      <li>
                        Full 6-month data range and 2 custom data requests per
                        month.
                      </li>
                      <li>Includes KTR & KU features for in-depth analysis.</li>
                    </ul>
                  </div>
                </CardBody>
                <CardFooter className="w-100">
                  <Button
                    className="w-100"
                    color="primary"
                    onClick={() => setVisible(true)}
                  >
                    BUY @ 29.99$
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col className="mt-4">
              <Card className="d-flex justify-content-center align-items-center text-center h-100">
                <CardBody>
                  <div className="">
                    <p className="text-white">Premium</p>
                    <ul>
                      <li>
                        Advanced tracking with access to 250 keywords and
                        categories.
                      </li>
                      <li>
                        Monitor up to 20 apps and track up to 20
                        keywords/categories.
                      </li>
                      <li>
                        Long-term data analysis (1 Year) with AI-powered
                        reports.
                      </li>
                      <li>
                        10 custom data requests per month for personalized
                        insights.
                      </li>
                      <li>
                        Unlimited access to advanced features like AUR & URV.
                      </li>
                    </ul>
                  </div>
                </CardBody>
                <CardFooter className="w-100">
                  <Button
                    className="w-100"
                    color="primary"
                    onClick={() => setVisible(true)}
                  >
                    BUY @ 59.99$
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}
