import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardFooter,
  Collapse,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import withRouter from "../../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-4.jpg"
import { Link } from "react-router-dom"
import PlanCards from "./PlansComponent"
// actions

const UserProfile = props => {
  document.title = " Profile | Dashboard"

  const dispatch = useDispatch()

  // const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState("")
  const [visible, setVisible] = useState(false)
  const storedUsername = localStorage.getItem("userName")
  const email = localStorage.getItem("userEmail")
  const [isOpenPaymentinfo, setIsOpenPaymentinfo] = useState(false)
  const togglePaymentinfo = () => setIsOpenPaymentinfo(!isOpenPaymentinfo)

  useEffect(() => {
    console.log("user profile page")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Minible" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{storedUsername}</p>
                        <p className="mb-0">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()

                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    value=""
                  />
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}

          <h4 className="card-title mb-4">Plans</h4>

          <PlanCards />

          <h4 className="card-title mb-4">Payment</h4>
          <Card>
            <Link
              onClick={togglePaymentinfo}
              className="collapsed text-reset"
              to="#"
            >
              <div className="p-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <i className="uil uil-bill text-primary h2"></i>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Payment Method</h5>
                    <p className="text-muted text-truncate mb-0">
                      Change your payment method
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-chevron-down accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </div>
            </Link>
            <Collapse isOpen={isOpenPaymentinfo}>
              <div className="p-4 border-top">
                <div>
                  {/* <h5 className="font-size-14 mb-3">Payment method :</h5> */}
                  <Row>
                    <Col lg={3} col={6}>
                      <div>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            name="pay-method"
                            id="pay-methodoption1"
                            className="card-radio-input"
                          />

                          <span className="card-radio text-center text-truncate">
                            <i className="uil uil-postcard d-block h2 mb-3"></i>
                            Credit / Debit Card
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col lg={3} col={6}>
                      <div>
                        <label className="card-radio-label">
                          <input
                            type="radio"
                            name="pay-method"
                            id="pay-methodoption2"
                            className="card-radio-input"
                          />

                          <span className="card-radio text-center text-truncate">
                            <i className="uil uil-paypal d-block h2 mb-3"></i>
                            Paypal
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-sm-end mt-2 mt-sm-0">
                        <Link to="#" className="btn btn-success">
                          <i className="uil uil-shopping-cart-alt me-1"></i>{" "}
                          Procced{" "}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
