import React, { useState } from "react"
import { Button, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"

export default function NavTabs({ onPlatformChange, tabFor }) {
  const [activeTab1, setactiveTab1] = useState("all")

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
      onPlatformChange(tab)
    }
  }
  return (
    <Nav pills style={{ marginLeft: 10 }}>
      <NavItem>
        {tabFor != "AppList" ? (
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "all",
            })}
            onClick={() => {
              toggle1("all")
            }}
          >
            <span className="d-block d-sm-block">All</span>
          </NavLink>
        ) : (
          <></>
        )}
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: activeTab1 === "sidequest",
          })}
          onClick={() => {
            toggle1("sidequest")
          }}
        >
          <span className="d-block d-sm-block">SideQuest</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: activeTab1 === "meta",
          })}
          onClick={() => {
            toggle1("meta")
          }}
        >
          <span className="d-block d-sm-block">Meta</span>
        </NavLink>
      </NavItem>
    </Nav>
  )
}
