import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import StepperProgress from "../../additional_components/common/Stepper"
import NavTabs from "../../additional_components/NavTabs"
import ProgressCard from "../../additional_components/common/ProgressCard"
//import PerformanceTable from "../additional_components/PerformanceTable"
import PerformanceDataTable from "../../additional_components/marketResearch/PerformanceDataTable"
import axios from "axios"
import { api } from "../../utils/variables"
import CategoryDataTable from "../../additional_components/marketResearch/CategoryDataTable"
import { waitFor } from "@testing-library/react"
import { useLocation } from "react-router-dom"

export default function AppsPub() {
  const [searchValue, setsearchValue] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)
  const [second_modal_toggle, setsecond_modal_toggle] = useState(false)
  const [categoryApps, setcategoryApps] = useState([])
  const [filteredApps, setCatFilteredApps] = useState([])
  const [selectedPlatform, changePlatform] = useState("sidequest")
  const [categoryData, setcategoryData] = useState([])
  const [dataAction, setDataAction] = useState("loading")
  const keywordarray = [
    "Action",
    "VR Shooter",
    "adventure",
    "Reality",
    "app",
    "racing",
  ]
  const [predefCategory, setpredef] = useState([])
  const storedAppsData = sessionStorage.getItem("categoryApps")
  const location = useLocation()
  const [loader, setLoader] = useState(true)

  const queryParams = new URLSearchParams(location.search)
  const gameID = queryParams.get("item_id")

  // alert(gameID)
  //enter publisher name
  useEffect(() => {
    try {
      fetchResult(gameID)
    } finally {
      setLoader(false)
    }
  }, [])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
    setsecond_modal_toggle(false)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const fetchResult = async id => {
    try {
      // Initial API call to get the action and details
      // let res = await axios.get(`${api}/tracker/keyword/${value}`, {})
      let res = await axios.get(
        `${api}/tracker/tracking/publisher?item_id=${id}`
      )
      setDataAction(res.data.action)
      //toggle_modal()
      // Polling until the action becomes "display"
      while (res.data.action.toLowerCase() !== "display") {
        console.log("Waiting for action to become 'display'...")

        // Wait for 2 seconds before fetching again
        await new Promise(resolve => setTimeout(resolve, 2000))

        // Fetch again to check the action
        res = await axios.get(
          `${api}/tracker/tracking/publisher?item_id=${id}`,
          {}
        )
        setDataAction(res.data.action)
      }

      // Once action is "display", fetch the games
      console.log("Action is 'display', fetching games...")
      // Check if the 'games' object is not empty
      const games = res.data?.data || [] // Default to empty array if undefined or empty
      setcategoryData(res.data?.data)
      setcategoryApps(res.data?.data)
      setCatFilteredApps(res.data?.data)

      // Store the games in sessionStorage (or an empty array if no games)
      sessionStorage.setItem("categoryApps", JSON.stringify(games))
      sessionStorage.setItem("filteredApps", JSON.stringify(games))

      //store category data
      sessionStorage.setItem("categoryData", JSON.stringify(res.data?.data))
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  // Callback function to handle the platform and select data from the navtabs
  const handlePlatformChange = platform => {
    changePlatform(platform) // Update the platform
    if (platform != "all") {
      const platformFilteredGames = categoryApps.filter(
        game => game?.platform === platform
      )
      setCatFilteredApps(platformFilteredGames)
    }
    //return all games
    else {
      const platformFilteredGames = categoryApps.filter(game => game)
      setCatFilteredApps(platformFilteredGames)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb
          title="Apps from the Publisher"
          breadcrumbItem="Apps from the Publisher"
        />
        <hr className="mt-4"></hr>
        <Row className="mt-4">
          <NavTabs onPlatformChange={handlePlatformChange} />
        </Row>

        <Row className="mt-2">
          <div className="mt-4">
            {categoryApps.length > 0 ? (
              loader ? (
                <Row className="mt-4">
                  <div className="d-flex justify-content-center">
                    <Spinner size="lg" color="primary" />
                  </div>
                </Row>
              ) : (
                <CategoryDataTable
                  inputdata={inputValue}
                  gamesData={filteredApps}
                />
              )
            ) : (
              <></>
            )}
          </div>
        </Row>
      </div>

      {/* Loading Modal */}
      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="lg"
        style={{ maxHeight: "100vh" }}
      >
        <ModalBody
          style={{
            maxHeight: "100vh",
            minHeight: "40vh",
            padding: "20px",
            // overflowY: "auto",
          }}
          className="position-relative"
        >
          <div className="d-flex justify-content-center">
            <Spinner className="mt-4" color="primary" />
          </div>
          <div className="">
            <p className="mt-4 text-center">
              <b>Wait While we are fetching your data</b>
            </p>
            <p className="mt-1 text-center">
              Funfact: loremipsum is the fun fact Funfact: loremipsum is the fun
              factFunfact: loremipsum is the fun factFunfactFunfact: loremipsum
              is the fun fact Funfact:
            </p>
          </div>
          <div className="mt-5 pb-5" style={{ maxWidth: "100%" }}>
            <StepperProgress
              closeModal={() => {
                toggle_modal()
              }}
              isFetched={dataAction}
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
