import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardFooter,
  Collapse,
} from "reactstrap"

export default function PlanCards() {
  const [visible, setVisible] = useState(false)
  return (
    <Card>
      <CardBody>
        <Row></Row>
        <Row classname="d-flex align-items-stretch">
          <div id="liveAlertPlaceholder">
            <div>
              <Alert
                color="info"
                isOpen={visible}
                toggle={() => setVisible(false)}
              >
                Buying option is not available for now!
              </Alert>
            </div>
          </div>
          <Col className="mt-4">
            <Card className="d-flex justify-content-center align-items-center text-center h-100">
              <CardBody>
                <div className="">
                  <p className="text-white">Free</p>
                  <ul>
                    <li>Ideal for beginners with essential tracking tools.</li>
                    <li>Free access to basic keyword and category analysis.</li>
                    <li>
                      Limited to 2 apps and 2 keywords/categories for tracking.
                    </li>
                  </ul>
                </div>
              </CardBody>
              <CardFooter className="w-100">
                <Button className="w-100" color="secondary">
                  Purchased
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col className="mt-4">
            <Card className="d-flex justify-content-center align-items-center text-center h-100">
              <CardBody>
                <div className="">
                  <p className="text-white">Standard</p>
                  <ul>
                    <li>
                      Advanced tracking with access to 100 keywords and
                      categories apps.
                    </li>
                    <li>
                      Monitor up to 10 apps and track up to 15
                      keywords/categories.
                    </li>
                    <li>
                      Full 6-month data range and 2 custom data requests per
                      month.
                    </li>
                    <li>Includes KTR & KU features for in-depth analysis.</li>
                  </ul>
                </div>
              </CardBody>
              <CardFooter className="w-100">
                <Button
                  className="w-100"
                  color="primary"
                  onClick={() => setVisible(true)}
                >
                  BUY @ 29.99$
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col className="mt-4">
            <Card className="d-flex justify-content-center align-items-center text-center h-100">
              <CardBody>
                <div className="">
                  <p className="text-white">Premium</p>
                  <ul>
                    <li>
                      Advanced tracking with access to 250 keywords and
                      categories.
                    </li>
                    <li>
                      Monitor up to 20 apps and track up to 20
                      keywords/categories.
                    </li>
                    <li>
                      Long-term data analysis (1 Year) with AI-powered reports.
                    </li>
                    <li>
                      10 custom data requests per month for personalized
                      insights.
                    </li>
                    <li>
                      Unlimited access to advanced features like AUR & URV.
                    </li>
                  </ul>
                </div>
              </CardBody>
              <CardFooter className="w-100">
                <Button
                  className="w-100"
                  color="primary"
                  onClick={() => setVisible(true)}
                >
                  BUY @ 59.99$
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
