import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import withRouter from "../Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import logoSm from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/trivlogo-sm.png"
import logoLight from "../../assets/images/logo-light.png"
import logo from "../../assets/images/brands/trivium logo.jpg"
import SidebarProfileContent from "./SidebarProfileContent"

const Sidebar = props => {
  const location = useLocation() // Hook to access location object

  function tToggle() {
    var body = document.body
    var windowSize = document.documentElement.clientWidth

    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")

    if (windowSize > 991) {
      body.getAttribute("data-sidebar-size") === "sm"
        ? body.setAttribute("data-sidebar-size", "lg")
        : body.setAttribute("data-sidebar-size", "sm")
    }
  }

  // useEffect(() => {
  //   // var verticalOverlay = document.getElementsByClassName("vertical-overlay");
  //   if (document.body.classList.contains("vertical-collpsed") && document.body.classList.contains("sidebar-enable")) {
  //     // verticalOverlay[0].addEventListener("click", function () {
  //       document.body.classList.remove("vertical-collpsed");
  //       document.body.classList.remove("sidebar-enable");

  //     };
  //   // }
  // });

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            {/* <span className="logo-sm">
              <img src={logoSm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="20" />
            </span> */}
            <h3>Trivium</h3>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoDark} alt="" height="40" />
            </span>
            <span className="logo-lg">
              <div className="d-flex justify-content-center mt-2">
                <img
                  src={logoDark}
                  alt=""
                  height="100"
                  className="align-self-center"
                />
              </div>
            </span>
            {/* <h3 className="AppName">Trivium</h3> */}
          </Link>
        </div>
        <button
          onClick={() => {
            tToggle()
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars"></i>
        </button>

        {location.pathname === "/profile" ||
        location.pathname === "/profile/plans" ||
        location.pathname === "/profile/payment" ? (
          <div className="sidebar-menu-scroll">
            {props.type !== "condensed" ? (
              <SidebarProfileContent />
            ) : (
              <SidebarProfileContent />
            )}
          </div>
        ) : (
          <div className="sidebar-menu-scroll">
            {props.type !== "condensed" ? (
              <SidebarContent />
            ) : (
              <SidebarContent />
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
