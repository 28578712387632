import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa"

import { api } from "../../utils/variables"
import classnames from "classnames"
import FeaturesTable from "../../additional_components/marketResearch/appTracking/FeaturesTable"
import axios from "axios"
import { Link } from "react-router-dom"

export default function ComparisonTabs({ appListData }) {
  const [activeTab1, setactiveTab1] = useState("1")
  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [appHistoryData, setAppHistoryData] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    console.log({ appListData })
    // if (appListData) fetchAppHistory(appListData)
    // console.log(appHistoryData)
  })
  const fetchAppHistory = async appListData => {
    try {
      // Use Promise.all to fetch data for each app based on item_id
      const appDataPromises = appListData.map(app => {
        const itemId = app.game_details.item_id
        // console.log(itemId)
        // Send item_id as a query parameter with GET request
        return axios.post(
          `${api}/tracker/tracking/app.history`,
          { item_id: itemId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
      })
      const responses = await Promise.all(appDataPromises)
      //console.log("Fetched responses:", responses)

      // Filter and map histories from responses
      const histories = responses
        .filter(response => response && response.data.history)
        .map(response => response.data.history)

      setAppHistoryData(histories)
      console.log(histories)
    } catch (error) {
      console.error("Error fetching app history data:", error)
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      <Nav pills>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Features</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
              fetchAppHistory(appListData)
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Performance</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          {appListData.length != 0 ? (
            <FeaturesTable appListData={appListData} />
          ) : (
            ""
          )}
          {/* <FeaturesTable appListData={appListData} /> */}
        </TabPane>
        <TabPane tabId="2">
          <div className="table-responsive">
            <Table className="table table-dark mb-0">
              {loader ? (
                <Row className="mt-4">
                  <div className="d-flex justify-content-center">
                    <Spinner size="lg" color="primary" />
                  </div>
                </Row>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th className="col-md-4" style={{ opacity: "70%" }}>
                        Feature
                      </th>
                      {appListData.map((app, index) => {
                        return (
                          <th className="text-center">
                            {app.game_details.name}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Downloads
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.historic_entries?.length
                        console.log(app)
                        console.log(len)
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }
                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastDownloads = lastEntry?.d || "NA"
                        const secondLastDownloads = secondLastEntry?.d || "NA"

                        return (
                          <>
                            {lastDownloads && secondLastDownloads ? (
                              lastDownloads > secondLastDownloads ? (
                                <td className="text-center">
                                  {lastDownloads - secondLastDownloads}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastDownloads < secondLastDownloads ? (
                                <td className="text-center">
                                  {lastDownloads}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                <td className="text-center">{lastDownloads}</td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Ratings
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        // Calculate the overall ratings for the last two elements
                        const getOverallRating = rating => {
                          const isTotalRatingAvailable =
                            rating?.total_rating || false
                          return isTotalRatingAvailable
                            ? (
                                (rating.rating_index["1"] +
                                  rating.rating_index["2"] * 2 +
                                  rating.rating_index["3"] * 3 +
                                  rating.rating_index["4"] * 4 +
                                  rating.rating_index["5"] * 5) /
                                rating.total_rating
                              ).toFixed(1)
                            : 0.0
                        }

                        const lastRating = getOverallRating(
                          app[len - 1]?.rating
                        )
                        const secondLastRating = getOverallRating(
                          app[len - 2]?.rating
                        )

                        return (
                          <td className="text-center">
                            {lastRating > secondLastRating ? (
                              <FaAngleDoubleUp color="green" />
                            ) : lastRating < secondLastRating ? (
                              <FaAngleDoubleDown />
                            ) : (
                              "No Change"
                            )}
                          </td>
                        )
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        KTR
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.historic_entries?.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        // const lastKTR = app[len - 1]?.ktr

                        // const secondLastKTR = app[len - 2]?.ktr

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastKTR = lastEntry?.ktr || 0
                        const secondLastKTR = secondLastEntry?.ktr || 0

                        return (
                          <>
                            {lastKTR && secondLastKTR ? (
                              lastKTR > secondLastKTR ? (
                                <td className="text-center">
                                  {Math.round(lastKTR * 100) / 100 -
                                    Math.round(secondLastKTR * 100) / 100}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastKTR < secondLastKTR ? (
                                <td className="text-center">
                                  {Math.round(lastKTR * 100) / 100}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                <td className="text-center">
                                  {Math.round(lastKTR * 100) / 100}
                                </td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        KU
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        // const lastKU = app[len - 1]?.ku

                        // const secondLastKU = app[len - 2]?.ku

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastKU = lastEntry?.ku || "NA"
                        const secondLastKU = secondLastEntry?.ku || "NA"

                        return (
                          <>
                            {lastKU && secondLastKU ? (
                              lastKU > secondLastKU ? (
                                <td className="text-center">
                                  {lastKU - secondLastKU}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastKU < secondLastKU ? (
                                <td className="text-center">
                                  {lastKU}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                <td className="text-center">{lastKU}</td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        AUR
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastAUR = lastEntry?.aur || "NA"
                        const secondLastAUR = secondLastEntry?.aur || "NA"

                        return (
                          <>
                            {lastAUR && secondLastAUR ? (
                              lastAUR > secondLastAUR ? (
                                <td className="text-center">
                                  {lastAUR - secondLastAUR}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastAUR < secondLastAUR ? (
                                <td className="text-center">
                                  {lastAUR}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                <td className="text-center">{lastAUR}</td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        URV
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastURV = lastEntry?.urv || "NA"
                        const secondLastURV = secondLastEntry?.urv || "NA"

                        return (
                          <>
                            {lastURV && secondLastURV ? (
                              lastURV > secondLastURV ? (
                                <td className="text-center">
                                  {lastURV - secondLastURV}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastURV < secondLastURV ? (
                                <td className="text-center">
                                  {lastURV}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                <td className="text-center">{lastURV}</td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        ASR
                      </td>
                      {appHistoryData.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastASR = lastEntry?.asr || "NA"
                        const secondLastASR = secondLastEntry?.asr || "NA"

                        return (
                          <>
                            {lastASR && secondLastASR ? (
                              lastASR > secondLastASR ? (
                                <td className="text-center">
                                  {(lastASR - secondLastASR).toFixed(2)}
                                  {"  "}
                                  <FaAngleDoubleUp color="green" />
                                </td>
                              ) : lastASR < secondLastASR ? (
                                <td className="text-center">
                                  {(secondLastASR - lastASR).toFixed(2)}
                                  {"  "}
                                  <FaAngleDoubleDown />
                                </td>
                              ) : (
                                // <td className="text-center">{(0).toFixed(2)}</td>
                                <td className="text-center">No Change</td>
                              )
                            ) : (
                              <td className="text-center">-</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}></td>
                      {appListData.map((app, index) => {
                        return (
                          <td td className="text-center">
                            <Link
                              to={`/gamedashboard?id=${app.game_details?.item_id}`}
                              className=""
                              state={{
                                app_id: app.game_details?.item_id,
                                data: app.game_details,
                                selected_tab: "track",
                              }}
                            >
                              <Button color="primary">More Details</Button>
                            </Link>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </>
              )}
            </Table>
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}
