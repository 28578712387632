import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../../assets/scss/datatables.scss"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"

const CategoryTrackingDataTable = ({ inputdata, categoryData }) => {
  const [modal_toggle, setmodal_toggle] = useState(false)
  const toggle_modal = () => {
    setmodal_toggle(prev => !prev)
  }

  const gameData = categoryData?.game_data
  const [topApps, setTopApps] = useState({})
  const [selectedCategory, setSelectedCategory] = useState("")

  //   useEffect(() => {
  //     if (selectedCatList) {
  //       //setSelectedApp(JSON.parse(selectedCatList))
  //     }
  //   }, [])
  const data = {
    columns: [
      {
        label: "Category",
        field: "Category",
        sort: "asc",
      },
      {
        label: "Position",
        field: "Position",
        sort: "asc",
      },
      {
        label: "Change",
        field: "Change",
        sort: "asc",
      },
      {
        label: "Popularity",
        field: "Popularity",
        sort: "asc",
      },
      {
        label: "Competitiveness",
        field: "Competitiveness",
        sort: "asc",
      },
      {
        label: "Top apps",
        field: "Topapps",
        sort: "asc",
      },

      //   {
      //     label: "Released",
      //     field: "released",
      //     sort: "asc",
      //   },

      // Add more columns here
    ],
    rows: Object.values(categoryData)
      .slice(0, -1)
      ?.map((category, index) => {
        const historicalData = category?.matching_historical_data
        const lastRank = historicalData?.at(-1)?.ranks[category?.game] || "> 30"
        const secondLastRank =
          historicalData?.at(-2)?.ranks[category?.game] || "> 30"

        let rankChange = "No Change"
        if (lastRank !== "> 30" && secondLastRank !== "> 30") {
          rankChange = lastRank - secondLastRank
          rankChange =
            rankChange > 0
              ? `+${rankChange}` // Positive change
              : `${rankChange}` // Negative change
        }

        return {
          Category: category?.category,
          Position:
            category?.matching_historical_data?.at(-1)?.ranks[category?.game] ||
            "> 30",
          Change: rankChange, //hidden field holds appname
          Popularity: "-",
          Competitiveness: "-", // Last entry,
          Topapps: (
            <Button
              color="primary"
              onClick={() => {
                toggle_modal()
                setTopApps(category?.matching_historical_data?.at(-1)?.ranks)
                setSelectedCategory(category?.category)
                console.log(gameData)
              }}
            >
              Top Apps
            </Button>
          ),
        }
      }),
  }

  return (
    <>
      <MDBDataTable responsive bordered data={data} noBottomColumns />

      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="md"
        style={{ maxHeight: "100vh" }}
      >
        <div className="modal-header bg-gradient-primary">
          <div className="d-flex justify-content-start gap-2 align-items-center">
            {/* <FaLock></FaLock> */}
            <h5 className="modal-title" id="staticBackdropLabel">
              Top Apps in {selectedCategory}
            </h5>
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              toggle_modal()
            }}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody
          className=""
          style={{
            maxHeight: "80vh",
            // minHeight: "40vh",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <div>
            <Row className="pb-2">
              <Col lg={12}>
                <Card className="mb-1">
                  {topApps && Object.keys(topApps).length === 0 ? (
                    <p>NO DATA</p>
                  ) : topApps ? (
                    Object.entries(topApps)
                      .sort(([, rankA], [, rankB]) => rankA - rankB) // Sort by rank in ascending order
                      .map(([app, rank], index) => {
                        const gameDetails = gameData.find(
                          game => game.item_id === app
                        ) // Find game by item_id
                        return (
                          <Row className="g-0 align-items-center" key={index}>
                            <Col md={4}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  gameDetails?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 70,
                                  maxHeight: 70,
                                  minWidth: 120,
                                  maxWidth: 120,
                                  objectFit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={8}>
                              <CardBody>
                                <div className="d-flex justify-content-between">
                                  <b className="text-white">
                                    {gameDetails?.name || "Demo"}
                                  </b>
                                  <p>{rank}</p>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        )
                      })
                  ) : (
                    <p>Loading...</p>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CategoryTrackingDataTable
