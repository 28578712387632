import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../../assets/scss/datatables.scss"
// import SmallAppCard from "./categoryAnalysis/SmallAppCard"
// import StarRatings from "./categoryAnalysis/StartRatings"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Modal,
  ModalBody,
  Row,
  Progress,
} from "reactstrap"
import ProgressCard from "../../common/ProgressCard"
const KeywordDataTable = ({ inputdata, keywordData }) => {
  const [modal_toggle, setmodal_toggle] = useState(false)
  const toggle_modal = () => {
    setmodal_toggle(prev => !prev)
  }

  const gameData = keywordData.game_data
  const [topApps, setTopApps] = useState({})
  const [selectedKeyword, setSelectedKeyword] = useState("")
  const data = {
    columns: [
      {
        label: "Keywords",
        field: "Keywords",
        sort: "asc",
      },
      {
        label: "Position",
        field: "Position",
        sort: "asc",
      },
      {
        label: "Change",
        field: "Change",
        sort: "asc",
      },
      {
        label: "Popularity",
        field: "Popularity",
        sort: "asc",
      },
      {
        label: "Competitiveness",
        field: "Competitiveness",
        sort: "asc",
      },
      {
        label: "Top apps",
        field: "Topapps",
        sort: "asc",
      },
    ],
    rows: Object.values(keywordData)
      .slice(0, -1)
      ?.map((keyword, index) => {
        const historicalData = keyword?.matching_historical_data
        const lastRank = historicalData?.at(-1)?.ranks[keyword?.game] || "> 30"
        const secondLastRank =
          historicalData?.at(-2)?.ranks[keyword?.game] || "> 30"

        let rankChange = "No Change"
        if (lastRank !== "> 30" && secondLastRank !== "> 30") {
          rankChange = lastRank - secondLastRank
          rankChange =
            rankChange > 0
              ? `+${rankChange}` // Positive change
              : `${rankChange}` // Negative change
        }

        return {
          Keywords: keyword?.keyword,
          Position: lastRank,
          Change: rankChange,
          Popularity: <Progress color="info" value="70" />,
          Competitiveness: <Progress color="info" value="60" />,
          Topapps: (
            <Button
              color="primary"
              onClick={() => {
                toggle_modal()
                setTopApps(historicalData?.at(-1)?.ranks)
                setSelectedKeyword(keyword?.keyword)
                console.log(gameData)
              }}
            >
              Top Apps
            </Button>
          ),
        }
      }),
  }

  return (
    <>
      <MDBDataTable responsive bordered data={data} noBottomColumns />

      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="md"
        style={{ maxHeight: "100vh" }}
      >
        <div className="modal-header bg-gradient-primary">
          <div className="d-flex justify-content-start gap-2 align-items-center">
            {/* <FaLock></FaLock> */}
            <h5 className="modal-title" id="staticBackdropLabel">
              Top Apps in {selectedKeyword}
            </h5>
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              toggle_modal()
            }}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody
          className=""
          style={{
            maxHeight: "80vh",
            // minHeight: "40vh",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <div>
            <Row className="pb-2">
              <Col lg={12}>
                <Card className="mb-1">
                  {topApps && Object.keys(topApps).length === 0 ? (
                    <p>NO DATA</p>
                  ) : topApps ? (
                    Object.entries(topApps)
                      .sort(([, rankA], [, rankB]) => rankA - rankB) // Sort by rank in ascending order
                      .map(([app, rank], index) => {
                        const gameDetails = gameData.find(
                          game => game.item_id === app
                        ) // Find game by item_id
                        return (
                          <Row className="g-0 align-items-center" key={index}>
                            <Col md={4}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  gameDetails?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 70,
                                  maxHeight: 70,
                                  minWidth: 120,
                                  maxWidth: 120,
                                  objectFit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={8}>
                              <CardBody>
                                <div className="d-flex justify-content-between">
                                  <b className="text-white">
                                    {gameDetails?.name || "Demo"}
                                  </b>
                                  <p>{rank}</p>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        )
                      })
                  ) : (
                    <p>Loading...</p>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default KeywordDataTable
