import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../store/actions"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useParams } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import logodark from "../../assets/images/trivlogo-sm.png"
import axios from "axios"
import { api } from "../../utils/variables"

export default function ResetPassword() {
  const dispatch = useDispatch()
  const { token } = useParams()

  const [password, setPassword] = useState("")
  const [user, setUser] = useState("")
  const [status, setStatus] = useState({ success: null, message: "" })
  const handleResetPassword = async password => {
    try {
      const response = await axios.post(`${api}/user/reset-password/${token}`, {
        password,
        // Include token from URL
      })
      setStatus({
        success: true,
        message: "Your password has been reset successfully!",
      })

      console.log("Password reset successful:", response.data)
    } catch (error) {
      console.log("Password reset failed:", error.response.data.error)

      setStatus({
        success: false,
        message: error.response?.data.error || error.message,
      })
    }
    console.log(user)
  }

  //   const restPasswordApi = pass => {
  //     console.log(pass)
  //     return axios.post(`${api}/user/forget_password`, {
  //       password: pass,
  //     })
  //   }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // username: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .required("Please Confirm Your Password")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: values => {
      handleResetPassword(values.password)

      //dispatch(registerUser(values))
    },
  })

  //   const selectAccountState = state => state.Account
  //   const AccountProperties = createSelector(selectAccountState, account => ({
  //     user: account.user,
  //     registrationError: account.registrationError,
  //     // loading: account.loading,
  //   }))

  //   const {
  //     user,
  //     registrationError,
  //     // loading
  //   } = useSelector(AccountProperties)
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password</h5>
                    <p className="text-muted"></p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {status.success == null ? (
                        <></>
                      ) : status.success ? (
                        <>
                          <Alert color="success">{status.message}</Alert>
                        </>
                      ) : (
                        <>
                          <Alert color="danger">{status.message}</Alert>
                        </>
                      )}

                      {/*
                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null} */}

                      {/* <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div> */}
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* Confirm Password Field */}
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          type="text"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-terms-condition-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-terms-condition-check"
                            >
                              I accept{" "}
                              <Link to="#" className="text-reset">
                                Terms and Conditions
                              </Link>
                            </label>
                          </div> */}

                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                          //   onClick={() => {
                          //     handleResetPassword(validation.touched.password)
                          //   }}
                        >
                          Submit
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="font-size-14 mb-3 title">
                            Sign up using
                          </h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            {/* <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter" />
                                </Link> */}
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Login
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                    <p>
                      © {new Date().getFullYear()} Minible. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                    </p>
                  </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
