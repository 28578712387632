import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  CardTitle,
  Button,
} from "reactstrap"
import Knob from "../Knob"
import { Link } from "react-router-dom"

export default function FeaturedApps({ app, key }) {
  const [readonly, setreadonly] = useState(80)
  return (
    <Card key={key} style={{ marginBottom: "20px" }}>
      <div className="d-flex justify-content-between gap-1">
        <CardImg
          top
          width="80px" // Adjust width as needed
          src={app.image_url}
          alt="Game Image"
          style={{
            height: "",
            width: "70%",
            objectFit: "cover",
            padding: 5,
          }}
        />
        <div style={{ flex: 1, margin: 5 }}>
          <Knob
            value={readonly}
            fgColor="#f06292"
            thickness={0.12}
            readOnly={true}
            height={40}
            width={40}
            onChange={e => {
              setreadonly(e)
            }}
          />{" "}
          {/* Place your Knob element here */}
        </div>
      </div>
      <CardBody>
        <CardTitle tag="h5">{app.name}</CardTitle>
        <Link
          to={`/gamedashboard?id=${app.item_id}`}
          className=""
          state={{ app_id: key, data: app }}
        >
          <Button color="primary" block>
            Details
          </Button>
        </Link>
      </CardBody>
    </Card>
  )
}
