import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import MarketResearch from "../pages/marketResearch"
import CategoryAnalysis from "../pages/MarketResearch/CategoryAnalysis"
import { components } from "react-select"
import GameDashboard from "../pages/IndividualApp/gameDashboard"
import AppTracking from "../pages/AppTracking/appTracking"
import AppsFromPublisher from "../pages/IndividualApp/AppsFromPublisher"
import KeywordTracking from "../pages/KeywordTracking/keywordTracking"
import CategoryTracking from "../pages/CategoryTracking/categoryTracking"
import KeywordTracking2 from "../pages/KeywordTracking/keywordTracking2"
import UserProfile from "../pages/UserProfile/userProfile"
import Plans from "../pages/UserProfile/userPlan"
import PaymentMethod from "../pages/UserProfile/userPayment"
import ResetPassword from "../pages/Authentication/ResetPassword"
import VerifyUser from "../pages/Authentication/VerifyUser"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/keywordSearch", component: <MarketResearch /> },
  { path: "/categoryanalysis", component: <CategoryAnalysis /> },
  // { path: "/gamedashboard", component: <GameDashboard /> },
  { path: "/gamedashboard", component: <GameDashboard /> },
  { path: "/apptracking", component: <AppTracking /> },
  { path: "/publisherapps", component: <AppsFromPublisher /> },
  { path: "/keywordtracking", component: <KeywordTracking /> },
  { path: "/categorytracking", component: <CategoryTracking /> },
  { path: "/keywordtracking2", component: <KeywordTracking2 /> },
  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/profile/plans", component: <Plans /> },
  { path: "/profile/payment", component: <PaymentMethod /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/user/reset-password/:token", component: <ResetPassword /> },
  { path: "/user/verify/:token", component: <VerifyUser /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
