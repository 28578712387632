import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardFooter,
  Collapse,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import withRouter from "../../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-4.jpg"
import { Link } from "react-router-dom"
import PlanCards from "./PlansComponent"
// actions

const Plans = props => {
  document.title = " Profile | Plans"

  const dispatch = useDispatch()

  // const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState("")
  const [visible, setVisible] = useState(false)
  const storedUsername = localStorage.getItem("userName")
  const email = localStorage.getItem("userEmail")
  const [isOpenPaymentinfo, setIsOpenPaymentinfo] = useState(false)
  const togglePaymentinfo = () => setIsOpenPaymentinfo(!isOpenPaymentinfo)

  useEffect(() => {
    console.log("user profile page")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Plans" breadcrumbItem="Plans" />

          <PlanCards />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Plans
